.video {
  min-width: 620px;
  height: 400px;
  border-radius: 10px;
  object-fit: cover;
}
.react-rotating-text-cursor {
  animation: blinking-cursor 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s
    infinite;
}
.video_modal {
  border-radius: 20px;
}
.dropdown-content {
  display: none;
}
.dropdown:hover .dropdown-content {
  display: block;
  padding: 1rem;
  background-color: #f7f7f7;
  border-radius: 5px;
  position: absolute;
  box-shadow: 0px 2px 1px gray;
  min-width: 270px;
  line-height: 2.5em;
  font-size: 14px;
}
.nav:hover {
  color: #7b47cc;
  transition: all 0.5s ease-in-out;
}
.dropdown-text {
  -webkit-transition: all 1s;
  transition: all 1s;
  margin-bottom: 10px;
}
.dropdown-text:hover {
  background-color: #7b47cc;
  border-radius: 2px;
  color: #fff;
  padding-left: 1rem;
}
.sidedrop-text {
  -webkit-transition: all 1s;
  transition: all 1s;
}
.sidedrop-text:hover {
  background-color: #7b47cc;
  border-radius: 2px;
  color: #fff;
  padding: 0.1rem 0.5rem 0.1rem 1rem;
}

@keyframes blinking-cursor {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media screen and (max-width: 991px) {
  .video {
    min-width: 100%;
    height: 346px;
    border-radius: 10px;
    object-fit: cover;
  }
}
